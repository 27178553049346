import React from 'react'

const colors = {
  shadow: "#6F655C",
  text: "black",
  dark: "#151314",
  light: "#FFF"
}

const SvgComponent = props => {
  const textColor = colors[props.theme || "dark"]
  console.log(props.shopId)
  let shopName = ''
  switch (props.shopId) {
    case 'kokorollburbank':
      shopName= 'BURBANK'
      break;
    case 'kokorolllacanada':
      shopName= 'LA CANADA'
      break;
    case 'kokorolltorrance':
      shopName= 'TORRANCE'
      break;
    default:
     

  }
  return (
    <svg
    aria-labelledby="svg-site-title"
     alt={props.alt}
      style={{
        maxWidth: "250px",
        width: "250px"
      }}
      viewBox="0 0 788.9 153" {...props}>
        <title id="svg-site-title">{props.alt}</title>
      <text
   
        transform="translate(1 43.77)"
        fill={textColor}
        fontFamily="'Helvetica'"
        fontWeight={900}
        fontSize={52.26}
      >
        {shopName}
      </text>
      <path
        fill="#EB038D"
        d="M472.2 75.4c2.5-6.5 6.5-10.8 12.1-13.9 14.8-8.2 34.8 3.1 35.1 19.9.2 12-4.5 22.7-10.5 32.6-8.1 13.3-19.7 23.2-33.4 30.7-2.3 1.3-4.3 1.6-6.9.5-21.4-9.9-43.4-35.8-44.2-62.1-.4-13.2 6.6-19.1 15.9-23.2 7.8-3.5 19.1-.1 25.6 7.1 2 2.3 3.7 5 6.3 8.4z"
      />
      <path
        fill={textColor}
        d="M296.3 48.9c21.1.2 39.9 15.5 44.7 36.4 4 17.5.8 33.6-11.2 47-21.1 23.6-54.8 17.2-69-1.1-11.4-14.7-15.1-30.5-9.9-47.8 5.9-19.4 22.5-35 45.4-34.5zm-1.1 78.6c15.8.1 27.7-12.1 28-28.9.4-16.7-12.2-30.5-27.9-30-14.3.5-26.9 10.6-27.1 29.1-.2 17.1 11.3 29.7 27 29.8zM167.9 98.3c.6 23.6-17.9 47-42.3 48.9-17.4 1.4-31.4-4.9-41.8-18.8-16-21.2-13.3-58.1 15.6-74.3 17.4-9.8 42.1-5.5 54.8 9.2 8.9 10.4 13.8 21.6 13.7 35zm-74.3-.1c0 12.8 6.8 23.6 17.4 27.5 10.1 3.7 19.7 2.3 27.7-4.7 8.7-7.5 11.4-17.7 9.3-28.8-3.3-17.3-18.1-26.8-34.3-22.5-12 3.1-20.1 14.6-20.1 28.5z"
      />
      <path
        fill="#EB038D"
        d="M688.2 141.6c-10.4 6-34.7 5.9-43.8-2.9-12.4-12.1-13.5-32.8-2.9-45.9 7-8.6 15.8-13.2 27.3-10.6 1.3.3 2.4.1 3.6-.1 5.5-1 9.5 1.3 12.6 5.7 2.8 4 5.9 7.8 7.4 13 7.6-3 14.7-3.8 21.8-.2 2.6 1.3 4.3-.1 5.3-2.3 1.2-2.5 2.7-4.7 4.4-6.8 9.9-11.7 20.2-12.7 31.8-2.6 6.2-4 12.4-10.3 20.3-3.7 5.3 4.4 5.4 10.8.9 17.5 7.4 8 6.1 18.1 5.2 27.7-.9 9.8-13.5 17.2-24.4 15.3-1.9-.3-4-.7-5.6-1.7-3.3-2.1-6.1-2.5-9.3.1-1 .8-2.9.9-4.3.7-9.6-.8-19.2 1.5-28.7-.2-1.4-.2-2.9.1-4.4.2-8.2.9-9.8.6-17.2-3.2zm-2.2-18.8c-1.1 0-1.9-.2-2.5 0-2.2.7-3.5 2.6-5.2 4-6.2 5-14.2 4.8-18.6-.4-4.9-5.7-6.2-17.1-2.6-23.7 2.7-5 8-7.4 13-5.6 3.4 1.2 6.3 2.7 8.6 5.5 1.3 1.7 2.9 2.8 5 1.2 2-1.5 1.8-3.8.6-5.3-3.4-4.2-5-11.5-13.3-8.6-1 .3-2.3-.6-3.5-.8-7.7-1.5-14.2 1.3-19.5 6.5-7 7-9 20.4-4.7 29.7 4.6 9.8 12.6 13.9 23.3 13.8 3.7 0 7.3-.8 11-1.2 1.4-.2 3.4.7 4.2-.7 2.4-4.5 5.4-8.7 4.2-14.4zm71.6-.3h13.2c2.9 0 5-1.1 5-4.2 0-5.1-1.2-9.7-6.1-12.6-7.2-4.3-15-3.3-21.5 3-5.7 5.5-6.8 13.2-2.8 20.2 4.5 8 12.6 11.8 20.4 9.6 6.7-1.9 9.9-6.4 9-12.6l-.6-.3c-3.2-.8-4.7 2.2-7.2 3.2-5.3 2-8.1.4-9.4-6.3zm-18.5-26.9c1.4 1.5 2.3 2.8 3.5 3.6 2.2 1.5 4.6 1.8 6.6-.5 1.7-2 1.1-5.5-1.2-7.3-.8-.6-1.7-1-2.6-1.3-6.3-2.5-13.3.5-17.4 7.3-1.7 2.8-1.6 6.7-5.4 8.3-1.1.5-1.2 2.7-.2 3.2 5.6 2.8 3.8 7.9 4 12.2.2 4.3 1.1 8.6-3.4 12-2.3 1.7-.9 4.9 2.3 5.2 3.9.4 7.9.3 11.8.2 4.4-.2 6.1-3.1 3.3-6.2-2.4-2.7-2.1-5.3-2.2-8.1-.1-2.2.1-4.4.1-6.6-.1-3-.3-5.5 3.4-7.2 3-1.3 2.9-4.1-.2-5.4-4.8-2.2-5-4.9-2.4-9.4zm-34 18.8c.1 2.6-1.6 3.2-3.7 3.7-1.9.4-3.9.7-5.7 1.4-5.6 2.1-7.6 7-5.4 12.5 2 4.9 6.1 7.5 11.9 6.7 4.4-.6 8.6-1.8 13 0 1.5.6 3.4.1 4.5-1.7s.7-3.4-.6-4.7c-2.3-2.2-1.9-4.9-1.8-7.6.1-2.2.3-4.4.2-6.7-.7-11.3-11-16.6-21.6-11.1-3.3 1.7-4.3 5.6-2.6 8.2 1.5 2.4 4.5 2.6 7.7.1 1.3-1.1 2.2-2.5 4.1-.8zm63.3-24.6c-3.1.9-6.3 3.1-9.5 5.4-1.6 1.1-2.1 2.8-1.1 4.5 1.1 1.9 2.7 2.8 4.9 1.8 2.9-1.3 5.8-2.7 8.6-4 1.8-.8 2.8-2.1 2-4.1-.6-1.9-1.8-3.4-4.9-3.6z"
      />
      <path
        fill={textColor}
        d="M403.9 97.6c9.3 4.1 11.2 11.6 11.4 19.9.2 9.1 0 18.2 0 27.8-6.2 1.5-12.2.5-19.3.8v-14.9c0-3.7.1-7.4-.1-11.1-.5-8.5-2.8-10.8-11.3-11.4-1.7-.1-3.4 0-5.6 0v-17c2.5-1.3 5.4-.6 8-1.3 4.8-1.4 7.8-5.9 7.5-11.4-.3-5.2-3.4-9-8.3-10-5.5-1.1-10.7 2.2-12.4 8-.8 2.9-.7 5.9-.7 8.8v59.5c-6.1 1.6-11.9.5-17.3.8-2.1-1.7-1.5-3.5-1.5-5.2V82.5c0-3 .1-6 .6-8.8 4.5-22.7 22.5-26.9 38.2-23.7 18.6 3.8 23.9 25.2 18.9 38-1.3 3.6-4.1 6.4-8.1 9.6zM198.6 104.9v40.4c-6.3 1.4-12.4.3-18.9.8V50.8h18.8V87c6.3 1.5 9.9-1.2 12.6-5.8 3.6-6.2 5.4-13.2 7.7-19.9 3.7-10.9 3.6-10.9 15-10.9h8.3c-5.5 14.7-8.4 29.7-18 42.3 0 .5.4 1.1 1.1 1.3 9.6 3.8 13.7 11.5 14.6 21.3.9 10 .4 20 .4 30.4h-18.4c-2-2-.9-4.6-1-6.9-.3-7.4.6-14.8-.6-22.1-1.4-8.4-4.9-11.4-13.4-11.7-2.5-.1-4.9-.1-8.2-.1zM46.2 145.9v-19.5c0-2 .1-3.9 0-5.9-.5-11.3-4.8-15.4-16.1-15.5h-5.5c-1.1 7-.4 13.9-.5 20.7-.1 6.6 0 13.2 0 20.1H5.3v-95h18.5v36.5c6.7 1.6 10-1.8 12.4-6.4 3.5-6.5 6.1-13.4 8.4-20.4 1.1-3.2 1.7-6.7 3.7-9.5h19.3c-7 14.2-8.7 29.8-18.5 42.4 13.7 5.6 16.3 17.5 16.4 30.7v21.6c-6.6.7-12.6.3-19.3.2zM624.4 126v19.9c-8.3.1-16.4.7-24.4-.7-10.4-1.8-15.8-6.8-17.8-17-.7-3.6-1-7.3-1-11-.1-20.7-.1-41.4-.1-62.1 0-1.5-.2-3 .9-4.5 5.7-.5 11.6-.4 17.3-.1 1.9 2 1.2 4.4 1.2 6.5.1 17.7 0 35.5 0 53.2 0 2 0 3.9.1 5.9.5 7.6 2.5 9.6 9.9 9.8 4.5.2 8.9.1 13.9.1zM531.7 50.8h18c1.3 2.7.7 5.2.7 7.6.1 17.5 0 35 0 52.5 0 2.2-.1 4.5.1 6.7.6 6.1 2.9 8.2 9 8.5 4.6.2 9.3 0 14.3 0 1.1 3.5.5 6.7.6 9.8.1 3.2 0 6.3 0 10-9.1.3-17.8.9-26.2-.9-8.4-1.8-14.1-6.7-15.8-15.5-.5-2.7-1.2-5.3-1.2-8-.1-22.7-.1-45.4-.1-68 .1-.9.4-1.6.6-2.7z"
      />
      <path
        fill="#EB038D"
        d="M764.9 115.9c-2.9 1.4-5.5 1.4-8.1 0-.3-2.6.8-4.6 3.3-4.9 2.9-.4 4.5 1.3 4.8 4.9zM705.6 131.4c-5.1-4.8-5.1-4.8.5-8.4 3.5 2.8 2 5.3-.5 8.4z"
      />
    </svg>
  )


}

export default SvgComponent
