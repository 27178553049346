import React from 'react'
import MagGlass from '../../react/MagGlassIcon'
import makeCloudinaryUrlForPhoto from '../../react/makeCloudinaryUrlForPhoto'
import {Link} from 'react-router-dom'



let MAX_ITEMS = 50

class MenuCategoryListView extends React.Component {

  state = {
    showAll: true
  }
  showRestOfCategory= () => {
    this.setState({ showAll: true })
  }
  render() {

    let className="photos grid"
    let isGallery = false
    let items = this.props.items
    let disableOrder = this.props.disableOrder
    let tagsCss = this.props.tags ? (' ' + this.props.tags) : ''
    let itemsToDisplay = this.state.showAll ? items : items.slice(0,MAX_ITEMS)
    return (
      <div className="menu-category-list-view">

        {/* List/Grid of Items */}
        <div className={className + tagsCss}>
          {itemsToDisplay.map(
            i => <MenuItem isGallery={isGallery}
              disableOrder={disableOrder}
              key={i.id} {...i}
              openPhotoSwipeGallery={this.props.openPhotoSwipeGallery}
              width={this.props.width}></MenuItem>)}
        </div>



        </div>
    );
  }
}

export default MenuCategoryListView

class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // console.log(name)
    let { id, name, photo, width='600', description, mandatory, price, originalPrice, modifiers } = this.props
    name = name && name.en
    description = description && description.en

    let cloudinaryURL
    if (photo) {
      cloudinaryURL = makeCloudinaryUrlForPhoto(photo, {width})
    }

    return (

        <section className="menu-item"
          aria-labelledby={'p_' + id}
          >


          {cloudinaryURL && <div
           aria-hidden="true"
            className="photo-container"
    
            onClick={this.props.openPhotoSwipeGallery.bind(this, id)}>
              <img
                src={cloudinaryURL}
                aria-hidden="true"
                alt={name + " " + "Product Image"}
                role="presentation"
              />
              {/* <MagGlass></MagGlass> */}
          </div>}



        <div className="content">
          <header>
            <h4
              id={'p_' + id}
              aria-label={`${name}, ${price} dollars`}
              className="food-name">{name}</h4>


          </header>
          {originalPrice ? <span 
          className="orignal-price">${originalPrice} </span> : null}
          <p 
          // aria-label={`${name} description`}
          className="menu-item__description">{description}</p>
        </div>
        {!this.props.disableOrder &&
          <Link
            className="product-link"
            role="button"
            aria-label={`add ${name}, ${price} dollars to cart`}
            title={`customize ${name} and add to cart`}
            to={`/products/${this.props.id}`}>
             
                <span role="none" className="plus">+</span> <span role="none" className="price">${price}</span>
             
          </Link>}



          {/* <button className="add-to-order-button" onClick={this.addToCart}>Order for Pickup</button> */}
          {/* <span className="ingredients">{mandatory}</span> */}
          {/* <span className="price">${price}</span> */}
        </section>

    );
  }
}
