import React, { Component, PureComponent, useContext, useEffect, useState } from 'react';
import {NavLink, Link, Switch, Route, Redirect} from 'react-router-dom'
import {RootRouterContext} from '../../index.js'
import OurStoryPage from './OurStoryPage'
import Catering from './Catering'
import Taconight from './Taconight'
import Order from './Order'
import Menu from './Menu'
import OrderAltadena from './OrderAltadena'
import Headers from './Headers'
import AutoMenu from './AutoMenu'
import CartBar from './CartBar'
import ThemeToggle from './ThemeToggle'
import ThemeContext from '../../Context/ThemeContext'
import LogoHero from './svg/LogoHero'

import './index.scss'
import './ThemeToggle/theme_night.scss'

export const customOrderAppPaths = ["/order", "/menu"]
export const StickyTopBar = () => { return null }

export const locations = [
  {shopId: "kokorollburbank",     basePath: "/burbank", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kokorolllacanada",    basePath: "/lacanada", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kokorolltorrance",    basePath: "/torrance", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kokorollhollywood",    basePath: "/hollywood", cssGlobalPrefix: 'kokorollburbank'},
  {shopId: "kokorollpa",    basePath: "/pasadena", cssGlobalPrefix: 'kokorollburbank'},
]

export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},

  {path: "/hqusers/storeRedirect/:anything", label: "Catering", hideFromNav: true, component: () => <Redirect to="/"></Redirect>},
  // {path: "/taco-night", label: "Event", component: Taconight},
  // {path: "/order-altadena", label: "Menu", component: OrderAltadena},
]

export function SiteNav ({shopId}) {

    const {rootHistory} = useContext(RootRouterContext)


    return (
      <>
       <CartBar shopId={shopId}></CartBar>
      <nav role="navigation" aria-label="navigation">
   
    <div className="pages-nav" >
      <ul aria-label="more links">
        <li><a className="page-link" href="/" onClick={(e) => {
          e.preventDefault()
          rootHistory.push('/')
        }}>All Locations</a></li>
        {pages
          .filter(({hideFromNav}) => !hideFromNav)
          .map(({path, label, external, onClick}) => (
            <li key={path}>
              {external ?
                <a       className="page-link" href={path}>{label}</a> :
                <NavLink className="page-link"
                
                exact to={path}>{label}</NavLink>}
            </li>
        ))}
      </ul>
      {/* <ThemeToggle /> */}
    </div>
  </nav>
  </>
  )
}

export function MultilocationHome () {
  // var preferredTheme = 'dark';
  // try {
  //   preferredTheme = localStorage.getItem('theme') || preferredTheme;
  // } catch (err) { }
  // const [theme, setTheme] = useState(preferredTheme)
  // window.__preferredTheme = theme
  // window.__setPreferredTheme = function (newTheme) {
  //   console.log('setting: ', newTheme)
  //   setTheme(newTheme)
  //   try {
  //     localStorage.setItem('theme', newTheme);
  //   } catch (err) {}
  // }
  // const {themeName: theme} = useContext(ThemeContext)
  const theme = "light"
  const heroLogoUrl = (theme !== 'dark') ?
    "https://afag.imgix.net/tacos-poncitlan-altadena/hero-logo-light.png?h=350" :
    "https://afag.imgix.net/tacos-poncitlan-altadena/hero-logo-dark.png?h=350"

  return(
    <div className={"site-content kokorollburbank" + " " + theme}>
      <Headers></Headers>
      <div className="home-page-hero" role="banner" aria-labelledby="hero-link">
        <h1>
          <Link to="/" aria-label="Kokoroll Cafe" id="hero-link" className="hero-link">
          
              <LogoHero
                theme={theme}
                ></LogoHero>
            {/* <img
              className="hero-logo"
               src={heroLogoUrl} alt={"Tacos Poncitlan"}/> */}
          </Link>
        </h1>
      </div>
  
      <Switch>
        <Route path="/order" component={Order} />
        <Route path="/menu" component={Order} />
        <Route path="/catering" component={Catering} />
        <Route path="/taco-night" component={Taconight} />
        <Route path="/" exact component={MultiHeader}></Route>
        <Route path="/hqusers/storeRedirect/:anything" render={() => {
          return(<Redirect to="/"></Redirect>)
        }}></Route>
        {/* <MultiHeader></MultiHeader> */}
      </Switch>
    </div>
  )
}

class MultiHeader extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper" >
        <div className="home-grid">


          <Route path="/www" render={() => (
            <p>hi</p>
          )}>

          </Route>

          <div id="pick-locations" role="main" aria-labelledby="location-header">
            <div className="location-header">
              {/* <div>
                <ThemeToggle></ThemeToggle>
              </div> */}
              <h2 id="location-header">Kokoroll Locations:</h2>
            
            </div>
            <div className="location-list">{locationsMetadata.map(({label, address, phone, hours, phoneLink, orderLink, className="", external}) => (
                <div className={"grid-item location " + className}>
                  <div className="location-list-content">
                    <h3 className="title">{label}</h3>
                    <p>{external ? 
                    <a href={orderLink} className="order-location-link">
                    <span className="order-button">Order Pickup / Delivery</span></a> :
                    <a href={orderLink} 
                      aria-label={"Order Pickup or Delivery for " + label + " location"}
                      className="order-location-link">
                      <span className="order-button">Order Pickup / Delivery</span></a>
                      }
                    </p>
                    <div className="address">
                      {address[0]} <br/>
                      {address[1]}
                    </div>
                    <div className="phone">
                      <a
                        className="phone-link"
                        href={`tel:${phoneLink}`}>{phone}</a>
                    </div>
                    <ul className="hours-of-operation">
                      {hours.map((hours) => {
                        return (<li key={hours.day}>
                          <span className="">{hours.day}: {hours.hours}</span>
                          {/* <span className="hours">{hours.hours}</span> */}
                        </li>)
                      })}
                    </ul>
                  </div>
                </div>
              ))}</div>
            
                    </div>
          </div>

      </div>
    );
  }
}


function RedirectToKokorollRamen () {
  useEffect(() => {
    window.location.href = "https://kokorollpokeandramen.com"
  })
  return null

}


export class Header extends PureComponent {
  render() {
    const shopId = this.props.shopId
    return (
      <div className="location-page">

       {(shopId=== 'kokorollpa') ? <div style={{
         
        }} className="banner">
         <RedirectToKokorollRamen url="https://kokorollpokeandramen.com"></RedirectToKokorollRamen>
        </div> : null}
        

        <div className="top-banner" style={{
          backgroundImage: "url(https://afag.imgix.net/kokoroll-cafe-burbank/food-on-table.jpg?w=1200&auto=format)"
        }}>
        <div role="main" aria-label="location information" className="info header">
          {locationsMetadata
            .filter((l) => l.id === this.props.shopId)
            // .slice(0,1)
            .map(({label, address, phone, hours, special, phoneLink, className=""}) => (
              <div className={"location"}>
                
                <div className="content">
                  {/* <h2>Kokoroll {label}</h2> */}
                  {address && <div aria-label="location address" className="address">
                    <p>
                      Address: <br/>
                      {address[0]} <br/>
                      {address[1]}
                    </p>
                  </div>}
                  {phone && <div
                   aria-label="location phone number"
                   className="phone">
                    Phone: <a
                      className="phone-link"
                      href={`tel:${phoneLink}`}>{phone}</a>
                  </div>}
                  {hours && <p 
                   aria-label="location hours of operation"
                  className="hours-of-operation">
                    {hours.map((hours) => {
                      return (<div 
                      
                      key={hours.day}>
                
                          <span className="">{hours.day}: {hours.hours}</span>
                          {/* <span className="hours">{hours.hours}</span> */}
                    
                      </div>)
                    })}
                  </p>}
                  <div 
                   aria-label="location online services"
                  className="order-action">
                    <a href="#order" className="order-button">Order Pickup / Delivery ↓</a>
                      <br />
                    {(shopId=== 'kokorollpa') && <a 
                   className="order-button"
                    href="https://kokorollcafe.com/catering">
                      Catering Menu →
                    </a>}
                  </div>
                
                </div>
              </div>
          ))}




          </div>
        </div>

        

        <AutoMenu 
        subMenuFilter={subMenuFilter}
        width={500}></AutoMenu>
      </div>
    );
  }
}



let locationsMetadata = [
  
  {
    id: 'kokorollburbank',
    label: "Burbank",
    address: ["214 E. Olive Ave", "Burbank, 91502"],
    phone: "(818) 561-4030",
    phoneLink: "+18185614030",
    orderLink: "/burbank",
    // external: true,
    hours: [
      { day: 'Mon-Sat', hours: '11am-8pm' },
      { day: 'Sunday', hours: '12pm-7pm' },
      // { day: 'Sun', hours: 'closed' }
    ]},

  {
    id: 'kokorolllacanada',
    label: "La Cañada",
    className: 'night',
    address: ["2265 Foothill Blvd", "La Canada, 91011"],
    phone: "(818) 330-9911",
    phoneLink: "+18183309911",
    orderLink: "/lacanada",
    hours: [
      { day: 'Mon-Sat', hours: '11:30am-7:30pm' },
      // { day: 'Fri-Sat', hours: '11:30am-8pm' },
      { day: 'Sun', hours: '12pm-7pm' },
    ]},

    {
      id: 'kokorolltorrance',
      label: "Torrance",
      className: 'night',
      address: ["22749 Hawthorne Blvd", "Torrance, CA, 90505"],
      phone: "424.360.6341",
      phoneLink: "+14243606341",
      orderLink: "/torrance",
      hours: [
        {day: 'last call', hours: '30min before closing'},
        {day: 'Tue-Thu', hours: '11:30am - 6:30pm'},
        { day: 'Fri-Sat', hours: '11:30am - 7pm' },
        // { day: 'Fri-Sat', hours: '11:30am - 7pm' },
        { day: 'Sun', hours: '12pm-6:30pm' },
        { day: 'Monday', hours: 'closed' },
      ]},
    {
      id: 'kokorollhollywood',
      label: "Hollywood",
      className: 'night',
      address: ["1819 N Cahuenga Blvd", "Los Angeles, CA 90028"],
      phone: "(323) 380-7808",
      phoneLink: "+13233807808",
      orderLink: "/hollywood",
      hours: [
        { day: 'Mon-Sun', hours: '11am-9:30pm' },

      ]},
      {
        id: 'kokorollpa',
        label: "Pasadena",
        className: 'night',
        address: ["1299 E Green St Suite 100", "Pasadena, 91106"],
        phone: "(626) 714-7032",
        phoneLink: "+16267147032",
        orderLink: "/pasadena",
        hours: [
          // { day: 'Soft Open Hours', hours: '' },
          // { day: 'Mon-Thu', hours: '11am-4pm' },
          // { day: 'Fri-Sun', hours: '4pm-9pm' },
          { day: 'Daily', hours: '11am-9pm' },
          // { day: 'From Oct.17', hours: 'op' },
          
        ]},
]

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {
    return true  
  }
  if (tag.indexOf('hide') !== -1) {
    return false
  }
  

  return true
}
