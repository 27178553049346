import React from 'react'
import './catering.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div 
    style={{
      background: "rgb(103,210,230)"
    }}
    className="catering-page catering page">


      <header
        style={{
          textAlign: "center",
          padding: "1em 0",
          background: "#f1f2f3"
        }}
      >
        <h1
        style={{
          fontSize: "1.4em",
          fontWeight: "800"
        }}
        >
          Kokoroll Cafe<br/>Pasadena<br/>Catering Menu
        </h1>
        <p>1299 E Green St Suite 100</p>
        {/* <p><a href="tel:+16267147032">(626) 714-7032</a></p> */}
      </header>
     
     <img 
     style={{
      display: "block",
      margin: "0 auto",
      width: "100%",
      maxWidth: "1200px",
      
     }}
     src="https://afag.imgix.net/kokoroll-pasadena/catering.png?w=900&auto=format" alt="" />

       
    </div>
  )
}
