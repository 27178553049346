import React from 'react'
// import './specials.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="taconight-page">
      <div className="top-banner" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/fetch/c_fill,w_1200/https://dl.airtable.com/MxH1Ew3WQiSfgs6AvoQX_IMG_0477.jpg)"}}>
        <div className="text with-background">
          <h1>Taco Night</h1>
          <p><strong>Thursday & Friday night</strong> 7pm - 10pm</p>
          <p>@ our Altadena Location only</p>
        </div>
      </div>

      <AutoMenu categorysToShow={['zoEn0IfzpgPiLMaqqqTq']} />
{/* 
      <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div> */}
    </div>
  )
}
