import React from 'react'

import {Link, Redirect} from 'react-router-dom'
export default function () {
  return(
    <div className="order-locations-page">
      <Redirect to="/burbank"></Redirect>
      <div className="top-banner choose-location" style={{backgroundImage: "url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_960/v1504048386/tacos-poncitlan-locations-google-maps_xxktk4.jpg)"}}>
        <div className="text">
          <h1>Select Location</h1>
        </div>
      </div>



        <div className="top-banner order-location">

          <div className="text">
            <h2>Altadena</h2>
            <p className="address">
              2291 Lincoln Ave
            </p>
            <p><Link to="/order-altadena" className="order-location-link">
            <span className="order-button">Order Pickup</span></Link></p>
            <p className="notes">(delivery coming soon)</p>
          </div>

        </div>

        <div className="top-banner order-location">
          {/* <Link to="/order/altadena"> */}
          <div className="text">
            <h2>Pasadena</h2>
            <p className="address">
              296 Allen Ave
            </p>
            <p><Link to="/order-pasadena" className="order-location-link">
            <span className="order-button">Order Pickup</span></Link></p>
            <p className="notes">(delivery coming soon)</p>
          </div>
        {/* </Link> */}
        </div>






    </div>
  )
}
